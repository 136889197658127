var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{ref:"first_name",attrs:{"rules":{ required: true },"vid":"first_name","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"First Name","label-for":"first-name-input"}},[_c('b-form-input',{attrs:{"id":"first-name-input","placeholder":"First Name","maxlength":"255","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])}),_c('validation-provider',{ref:"last_name",attrs:{"rules":{ required: true },"vid":"last_name","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last-name-input"}},[_c('b-form-input',{attrs:{"id":"user-lastname-input","placeholder":"Last Name","maxlength":"255","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{ref:"profile_image",attrs:{"rules":{ required: true },"vid":"profile_image","name":"Profile Image"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Photo","label-for":"photo-input"}},[_c('div',{staticClass:"photo-wrapper"},[_c('fileUpload',{attrs:{"lang-type":"en","field":"profile_img","width":80,"height":80,"img-format":"png","lang-ext":Object.assign({}, _vm.cropperSettings)},on:{"srcFileSet":_vm.cropSuccess,"crop-success":_vm.cropSuccess},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [(!_vm.imgDataUrl)?_c('b-img',{attrs:{"blank":"","blank-color":"#ccc","width":"80","alt":"placeholder"}}):_c('img',{attrs:{"src":_vm.imgDataUrl,"alt":"profile_image"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"media-body mt-75 ml-75"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.showCropperDialog}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Upload")])],1),_c('p',{staticClass:"card-text"},[_c('small',[_vm._v("Allowed JPG, GIF, BMP or PNG. Max size of 5MB")])]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)])],1)])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{ref:"phoneNumber",attrs:{"vid":"phone","name":"Phone Number","rules":_vm.defaultProgram && _vm.defaultProgram.compulsory_phone_number ? 'required|aom_phone': 'aom_phone'},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone-number-input"}},[_c('aom-tel-input',{attrs:{"id":"phone-number-input"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{ref:"email",attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email-input"}},[_c('b-form-input',{attrs:{"id":"email-input","placeholder":"Email","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('user-roles-select',{attrs:{"list-type":_vm.isProgramTypeTraining ? 'trainee': 'participant',"multiple-roles-enabled":_vm.multipleRolesEnabled},on:{"update":_vm.updateUserRole},model:{value:(_vm.selectedUserRoles),callback:function ($$v) {_vm.selectedUserRoles=$$v},expression:"selectedUserRoles"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.userEmailPreferences),callback:function ($$v) {_vm.userEmailPreferences=$$v},expression:"userEmailPreferences"}},[_vm._v(" Email Notifications ")])],1)],1),_c('b-row',[(_vm.hasRoleMentor)?_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('validation-provider',{ref:"allowedMentees",attrs:{"vid":"match_limit","name":"Allowed Mentees","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Number of Mentees allowed","label-for":"number-of-mentees-allowed"}},[_c('b-form-select',{attrs:{"id":"number-of-mentees-allowed","placeholder":"Select the Number of Mentees allowed","options":_vm.numberOfMenteesAllowedSet,"state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.numberOfMenteesAllowed),callback:function ($$v) {_vm.numberOfMenteesAllowed=$$v},expression:"numberOfMenteesAllowed"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,false,1147132325)})],1):_vm._e(),_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('validation-provider',{ref:"languages",attrs:{"name":"languages"}},[_c('b-form-group',{attrs:{"label":"Language","label-for":"selected-language"}},[_c('v-select',{attrs:{"id":"selected-language","selectable":function (option) { return option.id; },"dir":_vm.dir,"options":_vm.languages,"label":"name"},model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}})],1)],1)],1)],1),_c('b-row',[(_vm.isCreateUser)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-checkbox',{attrs:{"name":"participant-active","switch":"","inline":""},model:{value:(_vm.participantStatus),callback:function ($$v) {_vm.participantStatus=$$v},expression:"participantStatus"}},[_vm._v(" Active / InActive ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }